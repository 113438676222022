import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import {
    Form,
    FormGroup,
    Label,
    Row,
    Col,
    Input,
    FormFeedback,
    Button,
} from 'reactstrap';

import { ApplicationState } from 'store';
import { actionCreators as eventsActions } from 'store/eventsStore/actionCreators';
import { actionCreators as globalActions } from 'store/globalStore/actionCreators';
import {
    localStorageVariables,
    rhytmicGymnasticsEventParticipiantOptions,
} from 'constants/variables';
import { formatDate } from 'helpers/dateTimeHelper';
import BaseSelect, {
    SelectOption,
} from '../newEvent/components/selects/BaseSelect';
import {
    RhytmicGymnasticsEventParticipiant,
    BaseCategoryType,
} from 'constants/enums';
import {
    RhythmicGymnasticsEventRegistrationModel,
    RhythmicGymnasticsEventParticipiant,
} from '../interfaces';
import CountriesSelect from 'components/shared/CountriesSelect';
import { ImageUploadField } from 'components/shared/ImageUploadField';
import api from 'config/api';
import { prepareProfilePhotoUrl } from 'helpers/functions';
import RegistrationCategoryComponent from '../registration/components/RegistrationCategoryComponent';

export default function ParticipiantEditPage() {
    const { id, participiantId } = useParams();
    const { t } = useTranslation();
    const user = useSelector((state: ApplicationState) => state.account);
    const event = useSelector(
        (state: ApplicationState) => state.events?.eventDetails
    );
    const userCountry = useSelector(
        (state: ApplicationState) => state.global?.countryName
    );
    const history = useHistory();

    const dispatch = useDispatch();
    const [type, setType] = useState(
        RhytmicGymnasticsEventParticipiant.Gymnast
    );
    const [picture, setPicture] = useState<File | string | null>(null);
    const [suggest, setSuggest] = useState<
        RhythmicGymnasticsEventParticipiant[]
    >([]);
    const formikRef =
        useRef<FormikProps<RhythmicGymnasticsEventRegistrationModel>>(null);
    const selectedCategoryId = useRef('');
    const childRef = useRef<any>();

    const initialValues: RhythmicGymnasticsEventRegistrationModel = {
        type: type,
        licenseNumber: '',
        firstName: '',
        lastName: '',
        birthDate: '0001-01-01T00:00:00Z',
        coaches: '',
        club: '',
        country: userCountry || 'Ukraine',
        city: '',
        email: '',
        categoryId: '',
        id: '',
    };

    const [initialFormValues, setInitialFormValues] = useState(initialValues);

    const validationSchema = Yup.object().shape({
        type: Yup.number().required(
            t('errors.required', {
                name: t('words.type'),
            })
        ),
        firstName: Yup.string().required(
            t('errors.required', {
                name: t('words.firstName'),
            })
        ),
        lastName: Yup.string().required(
            t('errors.required', {
                name: t('words.lastName'),
            })
        ),
        email: Yup.string().email(),
        birthDate: Yup.string().when([], {
            is: () => type === RhytmicGymnasticsEventParticipiant.Gymnast,
            then: Yup.string().required(
                t('errors.required', {
                    name: t('words.dateOfBirth'),
                })
            ),
            otherwise: Yup.string().notRequired(),
        }),
        club: Yup.string().when([], {
            is: () => type === RhytmicGymnasticsEventParticipiant.Gymnast,
            then: Yup.string().required(
                t('errors.required', {
                    name: t('words.club'),
                })
            ),
            otherwise: Yup.string().notRequired(),
        }),
    });

    function prepareLabel(data: string) {
        return type === RhytmicGymnasticsEventParticipiant.Gymnast
            ? data + ' *'
            : data;
    }

    function updateParticipiant() {
        formikRef.current?.submitForm();
    }

    function submitParticipiant(
        model: RhythmicGymnasticsEventRegistrationModel
    ) {
        (async () => {
            dispatch(
                eventsActions.updateRhytmicGymnastyEventParticipiant(
                    id!,
                    participiantId!,
                    {
                        ...model,
                        categoryId: selectedCategoryId.current,
                        type: type,
                    }
                )
            );
            try {
                if (picture && typeof picture !== 'string') {
                    const formData = new FormData();
                    formData.append('File', picture!);
                    await api.files.uploadProfilePhoto(
                        participiantId!,
                        formData
                    );
                }
                await childRef?.current?.uploadFiles(participiantId);
            } catch (error) {
                dispatch(globalActions.setMessage(error));
            }
        })();
    }

    useEffect(() => {
        if (!localStorage.getItem(localStorageVariables.isUserAuth)) {
            history.push(`/auth/login?returnUrl=${window.location.pathname}`);
        }
        /* if (!event?.id) {
            id && dispatch(eventsActions.getRhytmicGymnastyEventDetails(id));
        } */
        const participiant = event?.participiants.find(
            (x) => x.id === participiantId
        );
        if (!participiant /* || participiant.createdUserId !== user?.id */) {
            history.goBack();
        } else {
            let birthdayDate = new Date(participiant?.birthDate);
            if (new Date(birthdayDate).toISOString().substr(0, 4) === '0000') {
                birthdayDate = new Date(0);
            }
            setInitialFormValues({
                ...(participiant as RhythmicGymnasticsEventRegistrationModel),
                birthDate: new Date(birthdayDate).toISOString().substr(0, 10),
            });

            setType(participiant.type);
            setPicture(prepareProfilePhotoUrl(participiant?.photo));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.role]);

    return user?.role ? (
        <>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <h4>{`${t('words.edit')} ${t(
                        'phrases.participantRefereeCoach'
                    )}`}</h4>
                    <p className="font-weight-bold">{event?.title}</p>
                    <p className="font-weight-bold">
                        {`${formatDate(event?.startDate)} - ${formatDate(
                            event?.endDate
                        )}`}
                    </p>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{ ...initialFormValues }}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        enableReinitialize
                        onSubmit={(values) => {
                            submitParticipiant(values);
                        }}
                    >
                        {({ values, errors, setFieldValue }) => (
                            <Form>
                                <FormGroup>
                                    <Label for="type">
                                        {t('words.type')} *
                                    </Label>
                                    <BaseSelect
                                        title={t('words.type')}
                                        options={
                                            rhytmicGymnasticsEventParticipiantOptions
                                        }
                                        value={rhytmicGymnasticsEventParticipiantOptions.find(
                                            (x) => x.value === type
                                        )}
                                        onChange={(value: SelectOption) =>
                                            setType(value.value)
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="licenseNumber">
                                        {t('words.licenseNumber')}
                                    </Label>
                                    <Input
                                        id="licenseNumber"
                                        name="licenseNumber"
                                        placeholder={t('words.licenseNumber')}
                                        type="text"
                                        value={values.licenseNumber}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'licenseNumber',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.licenseNumber}
                                    />
                                    <FormFeedback>
                                        {errors.licenseNumber}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup style={{ position: 'relative' }}>
                                    <Label for="lastName">
                                        {t('words.lastName')} *
                                    </Label>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        placeholder={t('words.lastName')}
                                        type="text"
                                        value={values.lastName}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'lastName',
                                                e.target.value
                                            );
                                            if (values.lastName.length > 2) {
                                                api.events
                                                    .getMyGymnasts(
                                                        values.lastName
                                                    )
                                                    .then((res) =>
                                                        setSuggest(res)
                                                    );
                                            }
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.lastName}
                                    />
                                    <FormFeedback>
                                        {errors.lastName}
                                    </FormFeedback>
                                    {suggest.length > 0 && (
                                        <div
                                            className="form-select form-select-options-container"
                                            style={{ top: 'calc(100% + 10px)' }}
                                        >
                                            {suggest.map((s) => (
                                                <div
                                                    key={s.id}
                                                    className="app-option"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'firstName',
                                                            s.firstName
                                                        );
                                                        setFieldValue(
                                                            'lastName',
                                                            s.lastName
                                                        );
                                                        setFieldValue(
                                                            'licenseNumber',
                                                            s.licenseNumber
                                                        );
                                                        setFieldValue(
                                                            'email',
                                                            s.email
                                                        );
                                                        if (
                                                            new Date(
                                                                s.birthDate
                                                            ).getFullYear() !==
                                                            1
                                                        ) {
                                                            setFieldValue(
                                                                'birthDate',
                                                                s.birthDate
                                                                    .toString()
                                                                    .substr(
                                                                        0,
                                                                        10
                                                                    )
                                                            );
                                                        }

                                                        setFieldValue(
                                                            'country',
                                                            s.country
                                                        );
                                                        setFieldValue(
                                                            'city',
                                                            s.city
                                                        );
                                                        setFieldValue(
                                                            'coaches',
                                                            s.coaches
                                                        );
                                                        setType(s.type);
                                                        setFieldValue(
                                                            'club',
                                                            s.club
                                                        );
                                                        setFieldValue(
                                                            'id',
                                                            s.id
                                                        );
                                                        setPicture(
                                                            prepareProfilePhotoUrl(
                                                                s.photo
                                                            )
                                                        );
                                                        setSuggest([]);
                                                    }}
                                                >
                                                    {`${s.lastName} ${s.firstName}`}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="firstName">
                                        {t('words.firstName')} *
                                    </Label>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        placeholder={t('words.firstName')}
                                        type="text"
                                        value={values.firstName}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'firstName',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.firstName}
                                    />
                                    <FormFeedback>
                                        {errors.firstName}
                                    </FormFeedback>
                                </FormGroup>
                                {type ===
                                    RhytmicGymnasticsEventParticipiant.Gymnast && (
                                    <>
                                        <FormGroup>
                                            <Label for="birthDate">
                                                {prepareLabel(
                                                    t('phrases.dateOfBirth')
                                                )}
                                            </Label>
                                            <Input
                                                id="birthDate"
                                                name="birthDate"
                                                placeholder={t(
                                                    'phrases.dateOfBirth'
                                                )}
                                                type="date"
                                                //@ts-ignore
                                                value={values.birthDate}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'birthDate',
                                                        e.target.value
                                                    );
                                                    //saveFormToLocalStorge(values);
                                                }}
                                                invalid={!!errors.birthDate}
                                            />
                                            <FormFeedback>
                                                {errors.birthDate}
                                            </FormFeedback>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="coaches">
                                                {t('words.coaches')}
                                            </Label>
                                            <Input
                                                id="coaches"
                                                name="coaches"
                                                placeholder={t('words.coaches')}
                                                type="text"
                                                value={values.coaches}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'coaches',
                                                        e.target.value
                                                    );
                                                    //saveFormToLocalStorge(values);
                                                }}
                                                invalid={!!errors.coaches}
                                            />
                                            <FormFeedback>
                                                {errors.coaches}
                                            </FormFeedback>
                                        </FormGroup>
                                    </>
                                )}
                                <FormGroup>
                                    <Label for="club">
                                        {prepareLabel(t('words.club'))}
                                    </Label>
                                    <Input
                                        id="club"
                                        name="club"
                                        placeholder={t('words.club')}
                                        type="text"
                                        value={values.club}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'club',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.club}
                                    />
                                    <FormFeedback>{errors.club}</FormFeedback>
                                </FormGroup>
                                <CountriesSelect
                                    value={values.country}
                                    onChange={(e: string) =>
                                        setFieldValue('country', e)
                                    }
                                    error={!!errors.country}
                                />
                                <FormGroup>
                                    <Label for="city">{t('words.city')}</Label>
                                    <Input
                                        id="city"
                                        name="city"
                                        placeholder={t('words.city')}
                                        type="text"
                                        value={values.city}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'city',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.city}
                                    />
                                    <FormFeedback>{errors.city}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">
                                        {t('words.email')}
                                    </Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        placeholder={t('words.email')}
                                        type="text"
                                        value={values.email}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'email',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.email}
                                    />
                                    <FormFeedback>{errors.email}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        {t('phrases.uploadYourPicture')}
                                    </Label>
                                    <ImageUploadField
                                        title={t('words.upload')}
                                        onChange={setPicture}
                                        file={picture}
                                    />
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                    {type === RhytmicGymnasticsEventParticipiant.Gymnast && (
                        <RegistrationCategoryComponent
                            categories={
                                event?.categories.filter(
                                    (x) =>
                                        x.type === BaseCategoryType.Individual
                                ) || []
                            }
                            onChange={(id: string) =>
                                (selectedCategoryId.current = id)
                            }
                            eventId={event?.id!}
                            ref={childRef}
                            forTeam={false}
                            mediaUploadEndDate={event?.audioDeadline}
                        />
                    )}
                    <Button
                        block
                        outline
                        style={{ marginBottom: 12 }}
                        color="secondary"
                        onClick={() => history.goBack()}
                    >
                        {t('words.cancel')}
                    </Button>
                    <Button
                        block
                        color="primary"
                        onClick={() => {
                            //setValidateOnChange(true);
                            updateParticipiant();
                        }}
                    >
                        {t('words.save')}
                    </Button>
                </Col>
            </Row>
        </>
    ) : null;
}
