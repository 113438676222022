import React, { useState } from 'react';

import Flag from 'react-world-flags';
import countryList from 'react-select-country-list';
import { Table } from 'reactstrap';

import { RhythmicGymnasticsEventTeam } from '../interfaces';
import images from 'assets/images';
import { prepareProfilePhotoUrl } from 'helpers/functions';
import { RhythmicGymnasticsEventParticipiantDetails } from '../interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { UserRole } from 'constants/enums';
import { Pencil, Trash3 } from 'react-bootstrap-icons';
import ConfirmationModal from 'components/shared/ConfirmationModal';
import { t } from 'i18next';
import eventsStore from 'store/eventsStore';

interface EventTeamComponentProps {
    team: RhythmicGymnasticsEventTeam;
    index: number;
    canBeEdited: boolean;
    categoryName: string;
    eventId: string;
}

export default function EventTeamComponent(props: EventTeamComponentProps) {
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.account);
    const [showMembers, setShowMembers] = useState(false);
    const [partToDelete, setPartToDelete] =
        useState<RhythmicGymnasticsEventParticipiantDetails | null>(null);
    const [deleteTeam, setDeleteTeam] = useState(false);

    function prepareRemoveText() {
        let text = '';
        if (partToDelete) {
            text = t('phrases.sureToRemoveParticipiantFromTeam', {
                name: `${partToDelete?.lastName} ${partToDelete?.firstName}`,
                team: props.team?.name,
            });
        } else if (deleteTeam) {
            text = t('phrases.sureToRemoveTeamFromCategory', {
                name: props.team?.name,
                category: props.categoryName,
            });
        }

        return text;
    }

    function handleClose() {
        setPartToDelete(null);
        setDeleteTeam(false);
    }

    function removeAction() {
        if (partToDelete) {
            alert('deleted');
        } else if (deleteTeam) {
            dispatch(
                eventsStore.actionCreators.removeRhytmicGymnastyEventTeamFromEvent(
                    props.eventId,
                    props.team.id
                )
            );
        }
    }

    return (
        <>
            <tr
                style={
                    showMembers
                        ? { backgroundColor: '#C3DEEB', cursor: 'pointer' }
                        : { cursor: 'pointer' }
                }
                key={props.team.name}
                onClick={() => setShowMembers(!showMembers)}
            >
                <td style={showMembers ? { boxShadow: 'none' } : {}}>
                    {props.index + 1}
                </td>
                <td
                    align="right"
                    style={showMembers ? { boxShadow: 'none' } : {}}
                >
                    <img src={images.icons.teamIcon} alt="tean" />
                </td>
                <td style={showMembers ? { boxShadow: 'none' } : {}}>
                    {props.team.name}
                </td>
                <td style={showMembers ? { boxShadow: 'none' } : {}}>
                    {props.team.club}
                </td>
                <td style={showMembers ? { boxShadow: 'none' } : {}}>
                    <Flag
                        code={countryList().getValue(props.team.country)}
                        height={16}
                        width={24}
                    />{' '}
                    {countryList().getValue(props.team.country)}
                </td>
                <td style={showMembers ? { boxShadow: 'none' } : {}}>
                    {props.canBeEdited &&
                        (user?.role === UserRole.ADMIN ||
                            props.team.createdUserId === user?.id) && (
                            <>
                                {/* <Pencil
                                                                cursor="pointer"
                                                                color="#086A98"
                                                                onClick={() => {
                                                                    history.push(
                                                                        `${location.pathname}/participiants/${part.id}`
                                                                    );
                                                                }}
                                                            /> */}
                                <Trash3
                                    className="participiant-remove-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteTeam(true);
                                    }}
                                />
                            </>
                        )}
                </td>
            </tr>
            {showMembers && (
                <tr>
                    <td colSpan={4} style={{ padding: 0 }}>
                        <Table striped borderless style={{ marginBottom: 0 }}>
                            <tbody>
                                {props.team.members.map(
                                    (
                                        part: RhythmicGymnasticsEventParticipiantDetails
                                    ) => (
                                        <tr
                                            key={
                                                part.firstName +
                                                '-' +
                                                part.lastName
                                            }
                                        >
                                            <td></td>
                                            <td>{`${part.lastName} ${part.firstName}`}</td>
                                            <td>
                                                {new Date(
                                                    part.birthDate
                                                ).getFullYear() !== 1
                                                    ? new Date(
                                                          part.birthDate
                                                      ).getFullYear()
                                                    : ''}
                                            </td>
                                            <td>
                                                {part.photo ? (
                                                    <img
                                                        className="participiant-table-photo"
                                                        alt="profile"
                                                        src={prepareProfilePhotoUrl(
                                                            part.photo
                                                        )}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            height: 36,
                                                            width: 36,
                                                        }}
                                                    ></div>
                                                )}
                                            </td>
                                            <td>
                                                {props.canBeEdited &&
                                                    (user?.role ===
                                                        UserRole.ADMIN ||
                                                        part.createdUserId ===
                                                            user?.id) && (
                                                        <>
                                                            {/* <Pencil
                                                                cursor="pointer"
                                                                color="#086A98"
                                                                onClick={() => {
                                                                    history.push(
                                                                        `${location.pathname}/participiants/${part.id}`
                                                                    );
                                                                }}
                                                            /> */}
                                                            <Trash3
                                                                className="participiant-remove-btn"
                                                                onClick={() =>
                                                                    setPartToDelete(
                                                                        part
                                                                    )
                                                                }
                                                            />
                                                        </>
                                                    )}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    </td>
                </tr>
            )}
            <ConfirmationModal
                isOpen={!!partToDelete || deleteTeam}
                text={prepareRemoveText()}
                onClose={handleClose}
                action={removeAction}
            />
        </>
    );
}
