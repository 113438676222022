import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import images from 'assets/images';

interface ConfirmationModalProps {
    isOpen: boolean;
    title?: string;
    text: string;
    action: () => void;
    cancelAction?: () => void;
    onClose: () => void;
    actionText?: string;
    cancelText?: string;
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
    const { t } = useTranslation();
    const closeBtn = (
        <img
            src={images.icons.closeCross}
            alt="close-modal"
            onClick={props.onClose}
            style={{ cursor: 'pointer' }}
        />
    );
    function actAndClose() {
        props.action();
        props.onClose();
    }
    function cancelAndClose() {
        props.cancelAction && props.cancelAction();
        props.onClose();
    }
    return (
        <Modal isOpen={props.isOpen} toggle={props.onClose} centered>
            <ModalHeader toggle={props.onClose} close={closeBtn}>
                {props.title}
            </ModalHeader>
            <ModalBody>{props.text}</ModalBody>
            <ModalFooter>
                <Button
                    className="btn-inline"
                    outline
                    color="secondary"
                    onClick={cancelAndClose}
                >
                    {props.cancelText ? props.cancelText : t('words.cancel')}
                </Button>
                <Button
                    className="btn-inline"
                    color="primary"
                    onClick={actAndClose}
                >
                    {props.actionText ? props.actionText : t('words.confirm')}
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
}
