import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Label,
} from 'reactstrap';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

import { regexHelper } from 'helpers/regexHelper';

export default function SetNewPasswordRequest(props: {
    action: (password: string) => void;
}) {
    const { t } = useTranslation();
    const [isPassword, setIsPassword] = React.useState(true);
    const [validateOnChange, setValidateOnChange] = useState(false);

    const schema = Yup.object().shape({
        password: Yup.string()
            .required(
                t('errors.required', {
                    name: t('words.password'),
                })
            )
            .matches(regexHelper.password),
    });
    return (
        <div className="row">
            <h4 className="mt-30">{t('phrases.resetYourPassword')}</h4>
            <p className="body-small text-center">
                {t('phrases.enterNewPassword')}
            </p>
            <Formik
                initialValues={{
                    password: '',
                }}
                validationSchema={schema}
                validateOnChange={validateOnChange}
                initialErrors={{}}
                onSubmit={(values) => {
                    props.action(values.password);
                }}
            >
                {({ handleSubmit, values, errors, setFieldValue }) => (
                    <Form
                        onSubmit={handleSubmit}
                        className="col-md-6 offset-md-3"
                    >
                        <FormGroup>
                            <Label for="password">{t('words.password')}</Label>
                            <InputGroup>
                                <Input
                                    name="password"
                                    placeholder={t('words.password')}
                                    type={isPassword ? 'password' : 'text'}
                                    value={values.password}
                                    onChange={(e) =>
                                        setFieldValue(
                                            'password',
                                            e.target.value
                                        )
                                    }
                                    invalid={!!errors.password}
                                />
                                <FormFeedback>{errors.password}</FormFeedback>
                                <div
                                    className="input-group-text"
                                    onClick={() => setIsPassword(!isPassword)}
                                >
                                    {isPassword ? <Eye /> : <EyeSlash />}
                                </div>
                            </InputGroup>
                        </FormGroup>
                        <Button
                            block
                            color="primary"
                            type="submit"
                            onClick={() => setValidateOnChange(true)}
                        >
                            {t('phrases.changePassword').toUpperCase()}
                        </Button>
                    </Form>
                )}
            </Formik>
            <br />
            <div className="bottom-massages-group">
                <p className="body-regular text-center">
                    {t('phrases.backTo')}{' '}
                    <a href="auth/login">{t('words.login')}</a>
                </p>
                <p className="body-regular text-center">
                    {t('phrases.doNotHaheAnAccount')}{' '}
                    <a href="/registration">{t('phrases.registerHere')}</a>
                </p>
            </div>
        </div>
    );
}
