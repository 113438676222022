import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import {
    Form,
    FormGroup,
    Label,
    Row,
    Col,
    Input,
    FormFeedback,
    Button,
} from 'reactstrap';

import { ApplicationState } from 'store';
import { actionCreators as eventsActions } from 'store/eventsStore/actionCreators';
import { actionCreators as globalActions } from 'store/globalStore/actionCreators';
import { localStorageVariables } from 'constants/variables';
import { formatDate } from 'helpers/dateTimeHelper';
import { BaseCategoryType } from 'constants/enums';
import {
    RhythmicGymnasticsEventGroupRegistrationModel,
    RhythmicGymnasticsEventGroupMember,
} from '../interfaces';
import CountriesSelect from 'components/shared/CountriesSelect';
import RegistrationCategoryComponent from './components/RegistrationCategoryComponent';
import GroupMembersComponent from './components/GroupMembersComponent';
import api from 'config/api';

export default function GroupRegistrationPage() {
    const { id } = useParams();
    const { t } = useTranslation();
    const user = useSelector((state: ApplicationState) => state.account);
    const event = useSelector(
        (state: ApplicationState) => state.events?.eventDetails
    );
    const userCountry = useSelector(
        (state: ApplicationState) => state.global?.countryName
    );
    const history = useHistory();
    const dispatch = useDispatch();
    const [members, setMembers] = useState<
        RhythmicGymnasticsEventGroupMember[]
    >([]);
    const formikRef =
        useRef<FormikProps<RhythmicGymnasticsEventGroupRegistrationModel>>(
            null
        );
    const selectedCategoryId = useRef('');
    const childRef = useRef<any>();

    const initialFormValues: RhythmicGymnasticsEventGroupRegistrationModel = {
        categoryId: '',
        name: '',
        club: '',
        country: userCountry || 'Ukraine',
        city: '',
        contactPerson: '',
        email: '',
        coaches: '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(
            t('errors.required', {
                name: t('phrases.teamName'),
            })
        ),
        club: Yup.string().required(
            t('errors.required', {
                name: t('words.club'),
            })
        ),
    });

    function registerParticipiant() {
        formikRef.current?.submitForm();
    }

    function submitTeam(model: RhythmicGymnasticsEventGroupRegistrationModel) {
        (async () => {
            dispatch(globalActions.showLoader());
            let res = false;
            let createdSuccess = false;
            let newMembers: any;
            let team;
            try {
                team = await api.events.registerRhytmicGymastTeam(
                    event?.id || '',
                    {
                        ...model,
                        categoryId: selectedCategoryId.current,
                    }
                );
                createdSuccess = true;
            } catch {
                createdSuccess = false;
            }
            if (createdSuccess) {
                try {
                    await childRef?.current?.uploadFiles(team.id);
                } catch {
                    createdSuccess = false;
                }
                try {
                    newMembers =
                        await api.events.registerRhytmicGymastTeamMembers(
                            event?.id || '',
                            team.id,
                            members
                        );
                } catch {
                    createdSuccess = false;
                }
            }
            if (createdSuccess) {
                try {
                    const membersWithNewImages = members.filter(
                        (x) => typeof x.photo === 'object'
                    );
                    if (membersWithNewImages.length > 0) {
                        membersWithNewImages.forEach(async (member) => {
                            const id =
                                member.id ||
                                newMembers.find(
                                    (x: {
                                        firstName: string;
                                        lastName: string;
                                        id: string;
                                    }) =>
                                        x.firstName === member.firstName &&
                                        x.lastName === member.lastName
                                ).id;
                            if (id && member.photo) {
                                const formData = new FormData();
                                formData.append('File', member.photo!);
                                await api.files.uploadProfilePhoto(
                                    id,
                                    formData
                                );
                            }
                        });
                        res = true;
                    } else {
                        res = true;
                    }
                } catch {
                    res = false;
                }
            }
            dispatch(globalActions.hideLoader());
            if (res) {
                dispatch(
                    globalActions.setMessage({
                        status: 200,
                        title: '',
                        message: t('phrases.newTeamCreated'),
                    })
                );
                history.push(`/events/${id}`);
            } else {
                dispatch(
                    globalActions.setMessage({
                        status: 500,
                        title: '',
                        message: t('phrases.newTeamCreatedWithWarnings'),
                    })
                );
            }
        })();
    }

    useEffect(() => {
        if (!localStorage.getItem(localStorageVariables.isUserAuth)) {
            history.push(`/auth/login?returnUrl=${window.location.pathname}`);
        }
        if (!event?.id) {
            id && dispatch(eventsActions.getRhytmicGymnastyEventDetails(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.role]);

    return user?.role ? (
        <>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <h4>{`${t('words.register')} ${t(
                        'phrases.participantsGroup'
                    )}`}</h4>
                    <p className="font-weight-bold">{event?.title}</p>
                    <p className="font-weight-bold">
                        {`${formatDate(event?.startDate)} - ${formatDate(
                            event?.endDate
                        )}`}
                    </p>
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialFormValues}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        onSubmit={(values) => {
                            submitTeam(values);
                        }}
                    >
                        {({ values, errors, setFieldValue }) => (
                            <Form>
                                <FormGroup>
                                    <Label for="name">
                                        {t('phrases.teamName')} *
                                    </Label>
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder={t('phrases.teamName')}
                                        type="text"
                                        value={values.name}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'name',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.name}
                                    />
                                    <FormFeedback>{errors.name}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="club">
                                        {t('words.club')} *
                                    </Label>
                                    <Input
                                        id="club"
                                        name="club"
                                        placeholder={t('words.club')}
                                        type="text"
                                        value={values.club}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'club',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.club}
                                    />
                                    <FormFeedback>{errors.club}</FormFeedback>
                                </FormGroup>
                                <CountriesSelect
                                    value={values.country}
                                    onChange={(e: string) =>
                                        setFieldValue('country', e)
                                    }
                                    error={!!errors.country}
                                />
                                <FormGroup>
                                    <Label for="city">{t('words.city')}</Label>
                                    <Input
                                        id="city"
                                        name="city"
                                        placeholder={t('words.city')}
                                        type="text"
                                        value={values.city}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'city',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.city}
                                    />
                                    <FormFeedback>{errors.city}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="contactPerson">
                                        {t('words.contactPerson')}
                                    </Label>
                                    <Input
                                        id="contactPerson"
                                        name="contactPerson"
                                        placeholder={t('words.contactPerson')}
                                        type="text"
                                        value={values.contactPerson}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'contactPerson',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.contactPerson}
                                    />
                                    <FormFeedback>
                                        {errors.contactPerson}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">
                                        {t('words.email')}
                                    </Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        placeholder={t('words.email')}
                                        type="text"
                                        value={values.email}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'email',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.email}
                                    />
                                    <FormFeedback>{errors.email}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="coaches">
                                        {t('words.coaches')}
                                    </Label>
                                    <Input
                                        id="coaches"
                                        name="coaches"
                                        placeholder={t('words.coaches')}
                                        type="text"
                                        value={values.coaches}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'coaches',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.coaches}
                                    />
                                    <FormFeedback>
                                        {errors.coaches}
                                    </FormFeedback>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                    <RegistrationCategoryComponent
                        categories={
                            event?.categories.filter(
                                (x) => x.type === BaseCategoryType.Group
                            ) || []
                        }
                        onChange={(id: string) =>
                            (selectedCategoryId.current = id)
                        }
                        eventId={event?.id!}
                        ref={childRef}
                        forTeam={true}
                        mediaUploadEndDate={event?.audioDeadline}
                    />
                    <GroupMembersComponent
                        members={members}
                        onAdd={(member: RhythmicGymnasticsEventGroupMember) =>
                            setMembers([...members, member])
                        }
                        onEdit={(
                            member: RhythmicGymnasticsEventGroupMember,
                            index: number
                        ) =>
                            setMembers(
                                members.map((x, i) =>
                                    i === index ? member : x
                                )
                            )
                        }
                        onDelete={(i: number) => {
                            let m = [...members];
                            m.splice(i, 1);
                            setMembers([...m]);
                        }}
                    />
                    <Button
                        block
                        outline
                        style={{ marginBottom: 12 }}
                        color="secondary"
                        onClick={() => history.goBack()}
                    >
                        {t('words.cancel')}
                    </Button>
                    <Button
                        block
                        color="primary"
                        onClick={() => {
                            //setValidateOnChange(true);
                            registerParticipiant();
                        }}
                    >
                        {t('words.toRegister')}
                    </Button>
                </Col>
            </Row>
        </>
    ) : null;
}
