import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';

import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import {
    RhythmicGymnasticsCategoryDetails,
    ApparatusMediaFile,
} from '../../interfaces';
import BaseSelect, {
    SelectOption,
} from 'components/events/newEvent/components/selects/BaseSelect';
import CategoryAppFileUpload from './CategoryAppFileUpload';
import api from 'config/api';

interface RegistrationCategoryComponentProps {
    categories: RhythmicGymnasticsCategoryDetails[];
    onChange: (id: string) => void;
    eventId: string;
    forTeam: boolean;
    mediaUploadEndDate?: Date;
}

export const RegistrationCategoryComponent = forwardRef(
    function RegistrationCategoryComponent(
        props: RegistrationCategoryComponentProps,
        ref
    ) {
        const { t } = useTranslation();
        const [categories, setCategories] = useState<SelectOption[]>([]);
        const [category, setCategory] = useState<SelectOption | null>(null);
        const [categoryDetails, setCategoryDetails] = useState<
            RhythmicGymnasticsCategoryDetails | undefined
        >(undefined);

        const files = useRef<ApparatusMediaFile[]>([]);

        function prepareCategoriesOption(
            categories: RhythmicGymnasticsCategoryDetails[]
        ) {
            return categories.map((cat) => ({
                text: cat.title,
                value: cat.id,
            }));
        }

        function addAppFile(file: ApparatusMediaFile) {
            files.current.push(file);
        }

        function removeAppFile(app: string) {
            files.current = files.current.filter((x) => x.app !== app);
        }

        useImperativeHandle(ref, () => ({
            uploadFiles(id: string) {
                const formData = new FormData();

                /* const uploadLogo = api.files
                                    .uploadEventLogo(newEvent.id, logoFormData)
                                    .then((response) => {
                                        newEvent.logo = response;
                                    }) */
                if (files.current.length > 0) {
                    files.current.forEach((x) => {
                        const fileExtension = x.file.name.substr(
                            x.file.name.lastIndexOf('.')
                        );
                        const updated = new File(
                            [x.file],
                            x.app + fileExtension,
                            {
                                type: x.file.type,
                                lastModified: x.file.lastModified,
                            }
                        );
                        formData.append('File', updated);
                    });
                    return props.forTeam
                        ? api.files.uploadTeamMediaFiles(
                              props.eventId,
                              categoryDetails?.id || '',
                              id,
                              formData
                          )
                        : api.files.uploadParticipiantMediaFiles(
                              props.eventId,
                              categoryDetails?.id || '',
                              id,
                              formData
                          );
                }
            },
            clearFiles() {
                files.current = [];
                setCategory(
                    categories.find((x) => x.value !== category?.value) || null
                );
            },
        }));

        useEffect(() => {
            if (props.categories.length > 0 && !category) {
                const options = prepareCategoriesOption(props.categories);
                setCategories(options);
                setCategory(options[0]);
                setCategoryDetails(props.categories[0]);
            } else {
                setCategoryDetails(
                    props.categories.find((x) => x.id === category?.value)
                );
            }
            props.onChange(category?.value);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.categories, category]);
        return category ? (
            <>
                <FormGroup>
                    <Label for="type">{t('words.category')} *</Label>
                    <BaseSelect
                        title={t('words.category')}
                        onChange={(category: SelectOption) => {
                            props.onChange(category.value);
                            setCategory(category);
                        }}
                        options={categories}
                        value={category}
                    />
                </FormGroup>
                {categoryDetails && (
                    <div style={{ marginBottom: 64 }}>
                        <div
                            style={{
                                textAlign: 'end',
                                margin: '32px 0 12px 0',
                            }}
                        >
                            {t('words.addAudio')}
                        </div>
                        <CategoryAppFileUpload
                            apps={categoryDetails!.firstApp}
                            appsNumber={1}
                            onAdd={addAppFile}
                            onRemove={removeAppFile}
                            mediaUploadEndDate={props.mediaUploadEndDate}
                            zIndex={7}
                        />
                        <CategoryAppFileUpload
                            apps={categoryDetails!.secondApp}
                            appsNumber={2}
                            onAdd={addAppFile}
                            onRemove={removeAppFile}
                            mediaUploadEndDate={props.mediaUploadEndDate}
                            zIndex={6}
                        />
                        <CategoryAppFileUpload
                            apps={categoryDetails!.thirdApp}
                            appsNumber={3}
                            onAdd={addAppFile}
                            onRemove={removeAppFile}
                            mediaUploadEndDate={props.mediaUploadEndDate}
                            zIndex={5}
                        />
                        <CategoryAppFileUpload
                            apps={categoryDetails!.fourthApp}
                            appsNumber={4}
                            onAdd={addAppFile}
                            onRemove={removeAppFile}
                            mediaUploadEndDate={props.mediaUploadEndDate}
                            zIndex={4}
                        />
                        <CategoryAppFileUpload
                            apps={categoryDetails!.fifthApp}
                            appsNumber={5}
                            onAdd={addAppFile}
                            onRemove={removeAppFile}
                            mediaUploadEndDate={props.mediaUploadEndDate}
                            zIndex={3}
                        />
                        <CategoryAppFileUpload
                            apps={categoryDetails!.sixthApp}
                            appsNumber={6}
                            onAdd={addAppFile}
                            onRemove={removeAppFile}
                            mediaUploadEndDate={props.mediaUploadEndDate}
                            zIndex={2}
                        />
                        <CategoryAppFileUpload
                            apps={categoryDetails!.finalApp}
                            appsNumber={0}
                            onAdd={addAppFile}
                            onRemove={removeAppFile}
                            mediaUploadEndDate={props.mediaUploadEndDate}
                            zIndex={1}
                        />
                    </div>
                )}
            </>
        ) : null;
    }
);

export default RegistrationCategoryComponent;
