import { AppThunkAction } from '..';
import { t } from 'i18next';

import { EventsActions } from './actions';
import api from 'config/api';
import { EventTypeFilter } from 'config/api/models';
import {
    RhythmicGymnasticsEvent,
    RhythmicGymnasticsEventDetailsEdit,
    RhythmicGymnasticsEventRegistrationModel,
} from 'components/events/interfaces';
import { UserRole } from 'constants/enums';
import { history } from 'index';

export const actionCreators = {
    getEvents:
        (
            type: EventTypeFilter = 'all',
            dateFrom: string = '',
            dateTo: string = '',
            country: string = '',
            city: string = ''
        ): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({ type: 'EVENTS_GET_DATA_START' });
            api.events
                .getEvents(type, dateFrom, dateTo, country, city)
                .then((response) =>
                    dispatch({
                        type: 'EVENTS_GET_DATA_SUCCESS',
                        payload: response,
                    })
                )
                .catch((error) => {
                    dispatch({ type: 'EVENTS_GET_DATA_ERROR' });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
        },
    addEvent:
        (event: RhythmicGymnasticsEvent): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'EVENTS_ADD_NEW_EVENT', payload: event });
        },
    getUserEvents: (): AppThunkAction<EventsActions> => (dispatch) => {
        dispatch({ type: 'GLOBAL_SHOW_LOADER' });
        dispatch({ type: 'EVENTS_GET_DATA_START' });
        api.events
            .getUserEvents()
            .then((response) =>
                dispatch({
                    type: 'EVENTS_GET_DATA_SUCCESS',
                    payload: response,
                })
            )
            .catch((error) => {
                dispatch({ type: 'EVENTS_GET_DATA_ERROR' });
                dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
            })
            .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
    },
    getNotPublishedEvents:
        (
            type: EventTypeFilter = 'all',
            dateFrom: string = '',
            dateTo: string = '',
            country: string = '',
            city: string = ''
        ): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({ type: 'EVENTS_GET_DATA_START' });
            api.events
                .getNotPublishedEvents(type, dateFrom, dateTo, country, city)
                .then((response) =>
                    dispatch({
                        type: 'EVENTS_GET_DATA_SUCCESS',
                        payload: response,
                    })
                )
                .catch((error) => {
                    dispatch({ type: 'EVENTS_GET_DATA_ERROR' });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
        },
    setNotPublishedEvents:
        (showNotPublished: boolean): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({
                type: 'EVENTS_SET_SHOW_NOT_PUBLISHED_EVENTS',
                payload: showNotPublished,
            });
        },
    setRhytmicGymnastyEventDetails:
        (
            event: RhythmicGymnasticsEventDetailsEdit
        ): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({
                type: 'EVENTS_GET_EVENT_DETAILS_SUCCESS',
                payload: event,
            });
        },
    getRhytmicGymnastyEventDetails:
        (id: string): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({ type: 'EVENTS_GET_EVENT_DETAILS_START' });
            api.events
                .getEventDetails(id)
                .then((response) =>
                    dispatch({
                        type: 'EVENTS_GET_EVENT_DETAILS_SUCCESS',
                        payload: response,
                    })
                )
                .catch((error) => {
                    dispatch({ type: 'EVENTS_GET_EVENT_DETAILS_ERROR' });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
        },
    postEvent:
        (id: string, role?: UserRole): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({ type: 'EVENTS_POST_EVENT_START' });
            api.events
                .postEvent(id)
                .then((response) => {
                    dispatch({
                        type: 'EVENTS_POST_EVENT_SUCCESS',
                        payload: response,
                    });
                    if (role === UserRole.ADMIN) {
                        dispatch({
                            type: 'GLOBAL_SET_MESSAGE',
                            payload: {
                                status: 200,
                                title: '',
                                message: t('phrases.eventPostedForAll'),
                            },
                        });
                        history.push('/');
                    } else {
                        dispatch({
                            type: 'GLOBAL_SET_MESSAGE',
                            payload: {
                                status: 200,
                                title: '',
                                message: t('phrases.eventPostedForReview'),
                            },
                        });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'EVENTS_POST_EVENT_ERROR' });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
        },
    deleteEvent:
        (id: string): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({ type: 'EVENTS_DELETE_EVENT_START' });
            api.events
                .postEvent(id)
                .then((response) => {
                    dispatch({
                        type: 'EVENTS_DELETE_EVENT_SUCCESS',
                        payload: response,
                    });
                    dispatch({
                        type: 'GLOBAL_SET_MESSAGE',
                        payload: {
                            status: 200,
                            title: '',
                            message: t('phrases.eventRemoved'),
                        },
                    });
                    history.push('/');
                })
                .catch((error) => {
                    dispatch({ type: 'EVENTS_DELETE_EVENT_ERROR' });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
        },
    updateRhytmicGymnastyEventParticipiant:
        (
            id: string,
            participiantId: string,
            model: RhythmicGymnasticsEventRegistrationModel
        ): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({ type: 'EVENTS_UPDATE_PARTICIPIANT_START' });
            api.events
                .updateRhytmicGynastParticipiant(id, participiantId, model)
                .then((response) => {
                    dispatch({
                        type: 'EVENTS_UPDATE_PARTICIPIANT_SUCCESS',
                        payload: response,
                    });
                    dispatch({
                        type: 'GLOBAL_SET_MESSAGE',
                        payload: {
                            status: 200,
                            title: '',
                            message: t('phrases.updatedSuccessfully'),
                        },
                    });
                })
                .catch((error) => {
                    dispatch({ type: 'EVENTS_UPDATE_PARTICIPIANT_ERROR' });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
        },
    removeRhytmicGymnastyEventParticipiantFromCategory:
        (
            eventId: string,
            participiantId: string,
            categoryId: string
        ): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({
                type: 'EVENTS_REMOVE_PARTICIPIANT_FROM_CATEGORY_START',
            });
            api.events
                .removeRhytmicGynastParticipiantFromCategory(
                    eventId,
                    participiantId,
                    categoryId
                )
                .then((response) => {
                    dispatch({
                        type: 'EVENTS_REMOVE_PARTICIPIANT_FROM_CATEGORY_SUCCESS',
                        payload: response,
                    });
                    dispatch({
                        type: 'GLOBAL_SET_MESSAGE',
                        payload: {
                            status: 200,
                            title: '',
                            message: t('phrases.updatedSuccessfully'),
                        },
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: 'EVENTS_REMOVE_PARTICIPIANT_FROM_CATEGORY_ERROR',
                    });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
        },
    removeRhytmicGymnastyEventParticipiantFromEvent:
        (
            eventId: string,
            participiantId: string
        ): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({
                type: 'EVENTS_REMOVE_PARTICIPIANT_FROM_EVENT_START',
            });
            api.events
                .removeRhytmicGynastParticipiantFromEvent(
                    eventId,
                    participiantId
                )
                .then((response) => {
                    dispatch({
                        type: 'EVENTS_REMOVE_PARTICIPIANT_FROM_EVENT_SUCCESS',
                        payload: response,
                    });
                    dispatch({
                        type: 'GLOBAL_SET_MESSAGE',
                        payload: {
                            status: 200,
                            title: '',
                            message: t('phrases.updatedSuccessfully'),
                        },
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: 'EVENTS_REMOVE_PARTICIPIANT_FROM_EVENT_ERROR',
                    });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
        },
    removeRhytmicGymnastyEventTeamFromEvent:
        (eventId: string, teamId: string): AppThunkAction<EventsActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({
                type: 'EVENTS_REMOVE_TEAM_FROM_EVENT_START',
            });
            api.events
                .removeRhytmicGymastTeamFromEvent(eventId, teamId)
                .then((response) => {
                    dispatch({
                        type: 'EVENTS_REMOVE_TEAM_FROM_EVENT_SUCCESS',
                        payload: response,
                    });
                    dispatch({
                        type: 'GLOBAL_SET_MESSAGE',
                        payload: {
                            status: 200,
                            title: '',
                            message: t('phrases.updatedSuccessfully'),
                        },
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: 'EVENTS_REMOVE_TEAM_FROM_EVENT_ERROR',
                    });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
        },
};
